export const AutodeskRoles = {
    AgentAssistAdmin: "agentassist_admin",
    AgentAssistUser: "agentassist_user",
    BIAAdmin1: "bia_admin_1",
    BIAUser1: "bia_user_1",
    BIAAdmin2: "bia_admin_2",
    BIAAdmin3: "bia_admin_3",
    BIAUser2: "bia_user_2",
    WatchdogAdmin: "watchdog_admin",
    WatchdogUser: "watchdog_user",
    SalesDashboardAdmin: "sales_dashboard_admin",
    SalesDashboardUser: "sales_dashboard_user",
    ExpensesDashboardAdmin: "expenses_dashboard_admin",
    ExpensesDashboardUser: "expenses_dashboard_user",
    BlackSwanAdmin: "black_swan_admin",
    BlackSwanUser: "black_swan_user",
    HealthcheckAdmin: "healthcheck_admin",
    HealthcheckUser: "healthcheck_user",
    FringeAdmin: "fringe_admin",
    FringeUser: "fringe_user",
    VaultAdmin: "vault_admin",
    VaultUser: "vault_user",
    TEAccrucialAdmin: "te_accrucial_admin",
    AIPAccrucialAdmin: "aip_accrucial_admin",
    FBPAccrucialAdmin: "fbp_accrucial_admin",
    AWSCCForecastAdmin: "aws_cc_forecast_admin",
    AWSCCForecastUser: "aws_cc_forecast_user",
    TaxTrendAnalysisAdmin: "tax_trend_analysis_admin",
    TaxTrendAnalysisUser: "tax_trend_analysis_user",
    LeaseReconAdmin: "lease_recon_admin",
    ESGDashboardAdmin: "esg_dashboard_admin",
    ACVForecastingDashboardAdminTopline: "acv_forecasting_topline_admin",
    ACVForecastingDashboardAdminAMER: "acv_forecasting_amer_admin",
    ForecastPlatformAdmin: "forecast_platform_admin",
    ApplauseSpendPlatformUser: "applause_spend_platform_user",
    CashFundForecastAdmin: "payroll_cash_fund_admin",
    TaxReconAdmin: "tax_recon_admin",
    SeesawAdmin: "seesaw_admin",
    MeetingDigestAdmin: "meeting_digest_admin",
    LumiTopicsAdmin: "lumi_topics_admin",
    FinbotAdmin: "finbot_admin",
    FinbotUser: "finbot_user",
    SSOLoginUserDev: "us-west-2_ARR238dcG_MSAzureAD",
    SSOLoginUserProd: "us-west-2_MczNswh0S_MSAzureAD",
};
